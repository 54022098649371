// consumer_apply_applyJourneyList 差旅PC首页行程单查询
const __request = require(`./__request/__request_contentType_json`);
// http://api-alpha.wholeok.com/doc.html#/yinzhilv-tmc/%E5%AE%A1%E6%89%B9%E7%94%B3%E8%AF%B7%E6%93%8D%E4%BD%9C/applyJourneyListUsingPOST
const consumer_apply_applyJourneyList = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/apply/applyJourneyList',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_apply_applyJourneyList;