// consumer_trains_account_accountStatus
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：

const consumer_trains_account_accountStatus = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/trains/account/accountStatus',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_trains_account_accountStatus;