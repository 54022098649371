import refund_change_policy_get from '@/lib/common-service/consumer_t-tp-refund-change-policy_get/index'
import consumer_apply_checkLoginUserPolicy from '@/lib/data-service/haolv-default/consumer_apply_checkLoginUserPolicy'
import getEvectionAllType from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_evection_logic_judge_option/1.0.1/index'
import CommonTravelerSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/common-traveler-selector/1.0.0/index.vue';
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info";
export default {
    data() {
        return {
            userInfo: null,

            allSelectedStaffList: [],
            //selectedStaffList: [],
            selectedStaffIdList: [],
            travelUserList: [], // 出行人

            currentActiveList: [], //员工当前选中列表

            isSelectedOrder: false,//是否已选出差单
            isSelectedMyself: false,//是否只能选当前登陆用户为出行人

            userError: false,

            evectionDetailType: '', // '001':因公出差带出差单, '002': 因公出差不带出差单, '101':因私出差
        }
    },
    props: {
        // 传入的出差单数据
        travelCurrent: {
            type: Object,
            default: null
        },
        useTravel: {
            type: [String, Number],
            default: ''
        },
        businessType: {
            type: [String, Number],
            default: ''
        },
        evectionRequired: {
            type: Number,
            default: 0
        },
        bookUpperBound: {
            type: Number,
            default: 6
        },
        isFreeApprove: {
            type: Number,
            default: 0
        }
    },
    components: {CommonTravelerSelector},
    created() {
    },
    /*async mounted() {
        let res = await get_user_info()
        this.userInfo = res.datas
        if (this.evectionRequired === 0 && this.bookUpperBound === 1 && this.isFreeApprove === 0) {
            this.isSelectedMyself = true
            this.travelUserList = [{
                userId: this.userInfo.id,
                staffName: this.userInfo.realName
            }]
            this.selectedStaffIdList = [this.userInfo.id]
            this.$emit('getSelectedStaffIdList', this.selectedStaffIdList)
        }
    },*/
    activated() {
        const __this = this
        this.$refs.aCommonTravelerSelector.init({
            __this: this,
            dialog: {
                title: `请选择出行人员`,
            },
            situation: `100`,
            select_mode: this.businessType === '2' ? 'single' : 'multiple',
            businessType: parseInt(this.businessType),
            get_params() {
                const p = {
                    applyJourneyId:  '',
                };
                return p;
            },
        });
        __this.$refs.aCommonTravelerSelector.$refs.aAvailableTravellerSelector.init_parameter.btn_ok_click_event_handler = (args) => {
            console.log(args);
            console.log('aAvailableTravellerSelector.btn_ok_click_event_handler')
            let travelUserList = args.selected_entity_list
            travelUserList.forEach((value, index) => {
                if (!value.userId) {
                    value.userId = value.id
                }
            })
            this.travelUserList = travelUserList

            //this.selectedStaffList = args.selected_entity_list;
            this.selectedStaffIdList = args.selected_id_list;
            this.$emit('getSelectedStaffIdList', this.selectedStaffIdList)
        };
        __this.$refs.aCommonTravelerSelector.$refs.aTravelerMultipleSelector.init_parameter.btn_ok_click_event_handler = (args) => {
            console.log(args);
            console.log('aTravelerMultipleSelector.btn_ok_click_event_handler')
            let travelUserList = args.selected_entity_list
            let staffList = []
            let staffIdList = []
            travelUserList.forEach((value, index) => {
                if (!value.userId) {
                    value.userId = value.id
                }
                let maxNo = 6;
                if (this.bookUpperBound === 1 && this.isFreeApprove === 1) {
                    maxNo = 6
                } else {
                    maxNo = this.bookUpperBound
                }
                if (index < maxNo) {
                    staffList.push(value)
                    staffIdList.push(value.userId)
                }
            })
            this.travelUserList = staffList
            this.selectedStaffIdList = staffIdList
            this.$emit('getSelectedStaffIdList', this.selectedStaffIdList)
        };

        __this.$refs.aCommonTravelerSelector.$refs.aAvailableTravellerSelector.init_parameter.btn_cancel_click_event_handler = () => {
            console.log('aAvailableTravellerSelector.btn_cancel_click_event_handler')
        };
        __this.$refs.aCommonTravelerSelector.$refs.aTravelerMultipleSelector.init_parameter.btn_cancel_click_event_handler = () => {
            console.log('aCommonTravelerSelector.btn_cancel_click_event_handler')
        };
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {
        travelCurrent(newVal) {
            console.log('cffff')
            this.checkOrder()
            if (newVal === null) {
                this.travelUserList = []
                this.selectedStaffIdList = []
                this.travelUserList = []
                //this.applyJourneyId = 0;
                if (this.useTravel === 1 && this.bookUpperBound === 1 && this.isFreeApprove === 0) {
                    this.travelUserList = [{
                        userId: this.userInfo.id,
                        staffName: this.userInfo.realName
                    }]
                    this.selectedStaffIdList = [this.userInfo.id]
                }
                this.$emit('getSelectedStaffIdList', this.selectedStaffIdList)
            } else {
                let resultStaffList = []
                let [...staffList] = newVal.active.staffList;
                // 因公选出差单的情况，不受帮订人数限制
                resultStaffList = staffList.filter(value => value.bookStatus === 1)
                this.allSelectedStaffList = newVal.active.staffList
                this.travelUserList = resultStaffList
                this.selectedStaffIdList = [...new Set(this.travelUserList.map(item => item.userId))]
                if (this.travelUserList.length === 0) {
                    this.$message('该行程没有可选的出行人')
                }
                console.log('11', this.allSelectedStaffList, this.selectedStaffIdList)
                this.$emit('getSelectedStaffIdList', this.selectedStaffIdList)
                this.$refs.aCommonTravelerSelector.init({
                    __this: this,
                    situation: '100',
                    businessType: this.businessType,
                    dialog: {
                        title: `请选择出行人员`,
                    },
                    get_params() {
                        const p = {
                            applyJourneyId: newVal.active.id,
                        };
                        return p;
                    },
                });
            }
        },
    },
    computed: {
        canChangeFactor() {
            let isOk = true;
            if(this.useTravel === 1 && this.evectionRequired === 1) {
                isOk = false;
            }
            if (this.travelCurrent !== null) {
                isOk = false
            }
            return isOk
        },
        canAddUser() {
            if (this.travelCurrent === null) {
                // 如果不是免审，则走正常
                if (this.isFreeApprove === 0) {
                    return this.travelUserList.length < this.bookUpperBound
                } else {
                    // 如果是免审，则不受帮订人数限制
                    return this.travelUserList.length < 6
                }
            } else {
                return true
            }
        },
        canDelUser() {
            if (this.travelCurrent === null) {
                return (this.bookUpperBound > 1 || (this.bookUpperBound === 1 && this.isFreeApprove === 1))
            } else {
                return true
            }
        },
    },
    filters: {},
    methods: {
        async setUserList() {
            let res = await get_user_info()
            this.userInfo = res.datas
            this.travelUserList = [{
                userId: this.userInfo.id,
                staffName: this.userInfo.realName
            }]
            this.selectedStaffIdList = [this.userInfo.id]
            this.$emit('getSelectedStaffIdList', this.selectedStaffIdList)
        },
        check() {
            return new Promise(((resolve, reject) => {
                let evectionParams = {
                    vm: this,
                    situation: '001',
                };
                const evectionQuery = getEvectionAllType(evectionParams);
                this.evectionDetailType = evectionQuery.evectionDetailType;
                // 如果必传出差单，缺选了出差单
                // if (evectionQuery.evectionRequired === 1 && this.travelCurrent !== null && this.selectedStaffIdList.length === 0) {
                //     reject({type: '001', msg: '出行人不能为空'});
                //     return
                // }
                // 如果必传出差单，缺选了出差单
                if (evectionQuery.evectionRequired === 1 && this.travelCurrent !== null && this.selectedStaffIdList.length === 0) {
                    reject({type: '001', msg: '未选择出行人'});
                    return
                }
                if (this.travelCurrent !== null && this.selectedStaffIdList.length === 0) {
                    reject({type: '001', msg: '未选择出行人'});
                    return
                }
                if (this.evectionDetailType === '002') {
                    let staffList = [];
                    this.travelUserList.forEach((value) => {
                        staffList.push(value.userId);
                    });
                    let checkParams = {
                        businessType: this.businessType,
                        userIds: staffList
                    };
                    if (staffList.length === 0) {
                        resolve({type: '001'})
                        return
                    }
                    consumer_apply_checkLoginUserPolicy(checkParams).then(res => {
                        resolve({type: '001'})
                    }, error => {
                        reject({type: '002', msg: error.datas.msg})
                    }).catch(err => {
                        reject({type: '002', msg: err.datas.msg})
                    })
                } else {
                    resolve('001')
                }
            }))
        },
        delTrainUser(val, index) {
            this.travelUserList.splice(index, 1)
            let staffIdList = []
            this.travelUserList.forEach(value => {
                staffIdList.push(value.userId)
            })
            this.selectedStaffIdList = staffIdList
            this.$emit('getSelectedStaffIdList', this.selectedStaffIdList)
        },
        // 有预设的弹出选择出差人框
        showTravelSelectUserPopup() {
            let activeUserIdList = []
            this.travelUserList.forEach(value => {
                console.log(value)
                activeUserIdList.push(value.userId)
            })
            this.currentActiveList = activeUserIdList
            this.$refs['aCommonTravelerSelector'].show()
        },
        // 没有预设弹出框选择员工
        showSelectUserPopup() {
            if(this.bookUpperBound > 1 || this.isFreeApprove === 1){
                let currentActiveList = []
                this.travelUserList.forEach(value => {
                    currentActiveList.push(value.userId)
                })
                this.currentActiveList = currentActiveList
                // this.notTravelDialogVisible = true
                this.$refs.aCommonTravelerSelector.show();
            } else {
                if(this.evectionRequired === 0 && this.bookUpperBound === 1 && this.isFreeApprove === 0){
                    this.$message({
                        type: 'warning',
                        message: '只能选自己为出行人'
                    })
                } else {
                    if(this.evectionRequired === 1 && this.travelCurrent === null){
                        // this.flightTravelError = true
                        // this.hotelTravelError = true
                        // this.trainTravelError = true
                        this.$message({
                            type: "warning",
                            message: '请选择出差单'
                        })
                    }else {
                        this.$message({
                            type: "warning",
                            message: '不能修改出差单既定行程'
                        })
                    }
                }
            }
        },

        //判断是否已选出差单
        checkOrder() {
            if (this.travelCurrent === null || this.travelCurrent === []) {
                this.isSelectedOrder = false
            } else {
                this.isSelectedOrder = true
            }
        },

        toggle(item) {
            if (item.bookStatus === 2) {
                return
            }
            let indexNum = this.selectedStaffIdList.indexOf(item.userId)
            if (indexNum > -1) {
                // 已有，取消
                this.selectedStaffIdList.splice(indexNum, 1)
            } else {
                // 没有，添加
                this.selectedStaffIdList.push(item.userId)
            }
            console.log(this.selectedStaffIdList)
            this.$emit('getSelectedStaffIdList', this.selectedStaffIdList)
        },
    }
}
